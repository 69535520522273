import * as React from "react";
import MediaBlock from "./MediaBlock";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import LinkWithTransition from "./LinkWithTransition";

interface CaseFooterProp {
    cases: any
}

const CaseFooter = ({cases}: CaseFooterProp) => {

    return (
        <>
            <div className={"o-section o-layout u-columns-24 c-cases--footer-cta"}>
                <p>Interesse gewekt? <LinkWithTransition to={`/cta`} className={"c-button c-button--yellow"}>KOM IN CONTACT</LinkWithTransition></p>
            </div>
            <div className={"o-section o-layout u-columns-24 c-cases--grid"}>
                {cases.map((caseItem) => {
                    return (
                        <LinkWithTransition to={`/case/${caseItem.slug}`} className={"u-colspan-24 u-colspan-12@lap c-cases--grid-item"} key={caseItem.slug}>
                            <div className={"c-cases--grid-case__title u-font-family--secondary u-text-color--white "}>
                                {caseItem.metatitle}
                            </div>
                            <LazyLoadComponent>
                                <MediaBlock
                                    item={caseItem.mainPhoto} additionalClasses={"o-object-fit--cover media"} />
                            </LazyLoadComponent>
                        </LinkWithTransition>
                    )
                })}
            </div>
        </>
    )
}

export default CaseFooter;
