import * as React from "react";
import {graphql} from "gatsby";
import ModularContentTemplate from "./ModularContentTemplate";
import * as _ from "lodash";
import {useEffect, useState} from "react";
import CaseFooter from "../components/CaseFooter";

export const CASE_DETAIL_QUERY = graphql`
query CaseDetailPageQuery($id: String!) {
  caseOverviewPage: contentfulCaseOverviewPage {
        metatitle
        cases {
            id
            metatitle
            title
            slug
            mainPhoto {
              file {
                contentType
                url
              }
            }
        }
  }
  content: contentfulCaseDetailPage(id: {eq: $id}) {
    id
    metatitle
    title
    brandBackgroundColor
    textKleur
    logoKleur
    mainPhoto {
      gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
      title
      
        file {
          url
          contentType
        }
    }
    hoofdFotoVideoPlaceholder {
        gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
    }
    contentParts {
    
    
      ... on ContentfulPagePart1KolomTekstAfbeeldingKlein {
        id
        position
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
          title
        }
        textTest {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePart2KolommenMetTekst {
        id
        title
        headText {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        textLeft {
          childMarkdownRemark {
            html
          }
        }
        textRight {
          childMarkdownRemark {
            html
          }
        }
        headTextRich {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        textLeftRich {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        textRightRich {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePartAfbeeldingVolbeeld {
        id
        title
        image {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
          title
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePart1KolomTekstMediaHalfVullend {
        id
        position
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        video {
          file {
            contentType
            url
          }
        }
        placeholder {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        
        richText {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePartTweeMediaNaastElkaarVullend {
        id
        title
        videoLeft {
          file {
            contentType
            url
          }
        }
        videoRight {
          file {
            contentType
            url
          }
        }
        placeholderLeft {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        placeholderRight {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePartVideoVolbeeld {
        id
        title
        video {
          file {
            contentType
            url
          }
        }
        placeholder {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      
      
      
    }
  }
}
`;

interface CaseDetailTemplateProps {
    data: any
}


const CaseDetailTemplate = ({data}: CaseDetailTemplateProps) => {

    const {
        caseOverviewPage,
        content: {
            id,
            metatitle,
            mainPhoto,
            hoofdFotoVideoPlaceholder,
            contentParts,
            title,
            brandBackgroundColor,
            textKleur,
            logoKleur
        }
    } = data;

    const [nextCases, setNextCases] = useState([]);
    useEffect(() => {
        const indexOfCurrentCase = _.findIndex(caseOverviewPage.cases, (c) => c.id === id);
        const indexOfFirstCase = typeof caseOverviewPage.cases[indexOfCurrentCase+1] === "undefined" ? 0 : indexOfCurrentCase+1;
        const indexOfLastCase = typeof caseOverviewPage.cases[indexOfFirstCase+1] === "undefined" ? 0 : indexOfFirstCase+1;

        const cases = [caseOverviewPage.cases[indexOfFirstCase], caseOverviewPage.cases[indexOfLastCase]];
        setNextCases(cases);
    }, [])

    return (
        <>
            <ModularContentTemplate
                metatitle={metatitle}
                mainPhoto={mainPhoto}
                mainPhotoPlaceholder={hoofdFotoVideoPlaceholder}
                contentParts={contentParts}
                title={title}
                showBreadcrumb={true}
                backgroundColor={brandBackgroundColor}
            textKleur={textKleur}
            logoKleur={logoKleur}/>

            <CaseFooter cases={nextCases} />
        </>
    )
};

export default CaseDetailTemplate;
